import httpUtil from "@/utils/httpUtil";

/**
 * 按域名查询oem配置
 * @param params
 * @returns {*}
 */
export const queryOemDomain = params => httpUtil.post("/oemConfig/queryOemDomain", params);


